import { Style } from "constants/style";
import { Layout } from "constants/layout";
import styled, { css } from "styled-components";
import Markdown from "react-markdown";
import { ContentLayout, SectionLayout } from "components/layout/Layout.styles";

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  /* padding: 2rem; */
  /* background-color: ${({ theme }) => theme.secondaryBackgroundColor}; */
  /* border-radius: ${Style.BORDER_RADIUS}; */
`;

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  flex-grow: 1;
`;

export const baseInputStyles = css`
  width: 100%;
  outline: none;
  color: ${({ theme }) => theme.primaryFontColor};
  background-color: inherit;
  border: none;
`;

export const MarkdownPreview = styled(Markdown)`
  ${baseInputStyles};
  min-height: 20rem;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  & > * {
    line-height: normal;
  }
`;

export const ContentToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.25rem ${Layout.PADDING_XS};
  font-size: 0.9rem;
  color: ${({ theme }) => theme.secondaryFontColor};
  border: 1px solid ${({ theme }) => theme.lineColor};
  border-top-left-radius: ${Style.BORDER_RADIUS_SM};
  border-top-right-radius: ${Style.BORDER_RADIUS_SM};
`;
export const PreviewButton = styled.button`
  font-size: 0.9rem;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.secondaryFontColor};
  cursor: pointer;
`;

export const ToolbarContainer = styled(SectionLayout)`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;

export const ToolbarLayout = styled(ContentLayout)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonGroup = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;
