import { useState } from "react";
import {
  ContentLayout,
  PageContentLayout,
  SectionLayout,
} from "components/layout/Layout.styles";
import {
  ButtonGroup,
  FormContainer,
  ToolbarContainer,
  ToolbarLayout,
} from "./CreatePost.styles";
import Typography from "components/typography/Typography";
import { Typography as TypographyConstants } from "constants/typography";
import formData from "content/createPost.json";
import DynamicForm from "util/DynamicForm";
import Button from "components/button/Button";

const CreatePost = () => {
  const [post, setPost] = useState({
    title: "",
    tags: [],
    markdownText: "",
  });
  const [showPreview, setShowPreview] = useState(false);
  const [form, setForm] = useState(null);

  const SUBHEADING = "New Post";

  // const submitPost = (postBody) => {
  //   let postTitle = title;
  //   let publishDate = new Date();

  //   let post = {
  //     title: postTitle,
  //     publishDate: publishDate,
  //     author: AUTHOR,
  //     body: postBody,
  //   };

  //   axios.post("/api/posts/create", post).then((res) => {
  //     console.log(res);
  //   });
  // };

  // const savePost = () => {
  //   console.log("Save post");
  //   // TODO: Implement save post logic
  // };

  // const discardPost = () => {
  //   console.log("Discard post");
  //   // TODO: Implement discard post logic
  // };

  const updateMarkdownText = (e) => {
    setPost((prev) => ({ ...prev, markdownText: e.target.value }));
  };

  const getWordCount = () => {
    const wordCount =
      post.markdownText === "" ? 0 : post.markdownText.trim().split(" ").length;
    return `${wordCount} word${wordCount !== 1 ? "s" : ""}`;
  };

  const handleUpdate = (formData) => {
    setForm(formData);
  };

  const { sections } = formData;

  return (
    <PageContentLayout>
      <ToolbarContainer>
        <ToolbarLayout>
          <Typography tag={TypographyConstants.H3}>{SUBHEADING}</Typography>
          <ButtonGroup>
            <Button type="secondary">Save</Button>
            <Button type="primary">Publish</Button>
          </ButtonGroup>
        </ToolbarLayout>
      </ToolbarContainer>
      <SectionLayout>
        <ContentLayout>
          <FormContainer>
            <DynamicForm data={sections} onUpdate={handleUpdate} />
          </FormContainer>
        </ContentLayout>
      </SectionLayout>
    </PageContentLayout>
  );
};

export default CreatePost;
