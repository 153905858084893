import CollapsibleCard from "components/card/CollapsibleCard";
import TagsInput from "components/form/TagsInput";
import TextField from "components/form/TextField";
import { useState } from "react";

function DynamicForm({ data, onUpdate }) {
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    onUpdate(formData);
  };

  const renderInput = (data, key) => {
    switch (data.type) {
      case "input":
        return (
          <TextField
            key={key}
            label={data.label}
            name={data.name}
            placeholder={data.placeholder}
            required={data.required}
            onChange={handleChange}
          />
        );
      case "tagsInput":
        return (
          <TagsInput
            key={key}
            label={data.label}
            name={data.name}
            placeholder={data.placeholder}
            required={data.required}
            onChange={handleChange}
          />
        );
      case "textarea":
        return (
          <TextField
            key={key}
            label={data.label}
            name={data.name}
            placeholder={data.placeholder}
            required={data.required}
            onChange={handleChange}
            multiline
          />
        );
      default:
        return null;
    }
  };

  const renderForm = (data) => {
    const { title, fields } = data;

    return (
      <CollapsibleCard heading={title}>
        {fields.map((data, index) => renderInput(data, index))}
      </CollapsibleCard>
    );
  };

  return <>{data.map((section) => renderForm(section))}</>;
}

export default DynamicForm;
